.page-container {
	height: 100vh;
	background: none;
}

.loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;

  .loading-txt {
    font-size: 14px;
    color: #FEBC5C;
  }
}