button {
	border: none;
	outline: none;
}

.primary {
	width: 100%;
	height: 50px;
	padding: 0;
	border-radius: 10px;
	background-image: linear-gradient(269deg, #3b414d 0%, #262a32 100%);
	font-size: 18px;
	color: #fff;
	font-weight: 600;

	&:active {
		background: #19191a;
	}
}

.btn {
	&.disabled {
		opacity: .3;
		color: #fff !important;
	}

	&.block {
		display: block;
	}

	&.btn-primary {
		line-height: 50px;
		@extend .primary;
	}

	&.border-radius-0-0 {
		border-radius: 0 0 10px 10px;
	}

	&.btn-empty-line {
		width: 100%;
		height: 50px;
		padding: 0;
		border-radius: 10px;
		border: 1px solid rgb(209 209 209 / 100%);
		background: #fff;
		font-size: 18px;
		color: #19191a;
		font-weight: 600;

		&:active {
			background: #fafafa;
		}
	}
}

.btn[role="button"] {
	display: flex;
	align-items: center;
	justify-content: center;
	@extend .primary;
}
