.modal-open {
	overflow: hidden;
}

.mask {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 600;
	background: rgb(0 0 0 / 40%);
}
