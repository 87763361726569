@import "./button";
@import "./card";
@import "./mask";
@import "./transition";

* {
	box-sizing: border-box;
}

body {
	font-family: "Helvetica Neue", Helvetica, "Segoe UI", Arial, Roboto, "PingFang SC", miui, "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
	background: #fdfdfd;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
